import { createTheme } from '@mui/material/styles';

// Update the Theme Typography's to add variant options
declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
    dividerTitle: React.CSSProperties;
    quietMessage: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    dividerTitle?: React.CSSProperties;
    quietMessage?: React.CSSProperties;
  }
}

// Update the Typography's variant options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    dividerTitle: true;
    quietMessage: true;
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    label: {
      marginRight: 8,
      fontWeight: 700,
      color: '#535353',
    },
    dividerTitle: {
      fontSize: '1.1rem',
      margin: '5px 0px',
      fontWeight: 'bold',
      color: '#757792',
    },
    quietMessage: {
      color: '#a2a3ad',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      // letterSpacing: 0.00938em,
    },
  },
});

export default theme;
